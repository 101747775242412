export const environment = {
  production: true,
  feUrl: 'https://platform.madsense.io',
  serviceUrl: 'https://social-api.madsense.io/api',
  madBuyServiceUrl: 'https://madbuy-be.madsense.io/api',
  madBuyReportingServiceUrl: 'https://madbuy-reporting.madsense.io/api',
  metaConfigId: '1220257238941913',
  metaAdsConfigId: '1521236798750152',
  metaInstagramConfigId: '792833459422160',
  metaAppId: '382794467576892',
  metaConfigId_2: '1584328929118107',
  metaAdsConfigId_2: '2082356485555226',
  metaInstagramConfigId_2: '974442101115637',
  metaAppId_2: '1590998431624896',
  client_id: '781k0u4pah1tr5', // Used for LinkedIn
  meta_company_id: ['dd3a9104-68e8-49d1-9d34-097fa615a367', '15df74d8-3e4c-415e-a5eb-2a1396661949'],
  redirect_uri:
    'https://social-api.madsense.io/api/social-media-connections/linkedin', // Replace with your actual redirect URI, Used for LinkedIn
  scope: 'w_member_social%2copenid%2cemail%2cprofile', // Define the scope of the access you are requesting, Used for LinkedIn
  sentryDsn: 'https://e2c9fdbe13f7335f833a9996f386f035@o4507844150427648.ingest.us.sentry.io/4507866426638336'
};
